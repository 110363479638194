import { useState } from "react";
// import {
//   FaFacebookF,
//   FaLinkedinIn,
//   FaInstagram,
//   FaTwitter,
// } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import axios from "axios";

const Footer = () => {
  const { userInfo } = useSelector((state) => state.login);
  const [email, setEmail] = useState("");

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (userInfo?.email) {
      await axios
        .post(
          "https://one0minutespte-backend.onrender.com/api/newsletter",
          { email },
          {
            headers: {
              Authorization: `${userInfo?.refreshToken}`,
            },
          }
        )
        .then((response) => {
          enqueueSnackbar(response?.data?.message, { variant: "success" });
        })
        .catch((error) => {
          enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
        });
      setEmail("");
    } else {
      window.location.href = "/auth";
    }
  };
  return (
    <div className="w-full bg-white px-4 py-10 md:px-10">
      <SnackbarProvider />
      <div className="m-auto grid max-w-[600px] gap-16 px-4  max-[780px]:grid-cols-2 md:max-w-[1480px]  md:grid-cols-5 md:px-0">
        <div className="col-span-2">
          <div className="flex items-center">
            <img src="./10.png" alt="" className="w-14 p-2" />{" "}
            <span className="text-[22px] font-medium">minutesPTE</span>
          </div>
          <p className="mt-2">
            We are your trusted platform for PTE (Pearson Test of English)
            preparation, providing you with the tools and resources you need to
            excel in your language proficiency exam. Whether you're a student
            aiming to study abroad, a professional seeking to enhance your
            career prospects, or an individual looking to improve your language
            skills, we've got you covered.
          </p>
          {/* <div className="flex gap-4 py-4">
            <div className="rounded-xl bg-orange-50 p-4">
              <FaFacebookF size={25} style={{ color: "orange-red" }} />
            </div>
            <div className="rounded-xl bg-orange-50 p-4">
              <FaLinkedinIn size={25} style={{ color: "orange-red" }} />
            </div>
            <div className="rounded-xl bg-orange-50 p-4">
              <FaInstagram size={25} style={{ color: "orange-red" }} />
            </div>
            <div className="rounded-xl bg-orange-50 p-4">
              <FaTwitter size={25} style={{ color: "orange-red" }} />
            </div>
          </div> */}
        </div>
        <div className="max-[780px]:col-span-2">
          <h3 className="text-2xl font-semibold">Newsletter</h3>
          <h3 className="py-2 text-[#6D737A]">Subscribe to our newsletter.</h3>
          <form className="py-4">
            <input
              className="w-full rounded bg-[#F2F3F4] p-4"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email here"
            />
            <button
              onClick={handleSubscribe}
              className="bg_accent my-4 rounded-md px-5 py-3 font-medium text-white max-[780px]:w-full"
            >
              Subscribe Now
            </button>
          </form>
        </div>
        <div className="w-max pb-4">
          <h3 className="text-2xl font-semibold">Join Telegram</h3>
          <img src="./telegram2.jpg" alt="" className="mt-2 w-44" />
        </div>
      </div>
      <hr className="mb-5" />
      <div className="flex items-center justify-end gap-5 text-sm text-[#6D737A]">
        <Link to="/privacypolicy">Privacy policy</Link>
        <Link to="/terms&conditions">Terms and conditions</Link>
      </div>
    </div>
  );
};

export default Footer;
