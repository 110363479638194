import Feedback from "../components/Feedback"
import Sell from "../components/Sell"
import Promo from "../components/Promo"
import Hero from "../components/Hero"
import CTA from "../components/CTA"


const Home = () => {
  return (
    <div>
      <Hero />
      <Promo />
      <Sell />
      <Feedback />
      <CTA />
    </div>
  );
};
export default Home;
