import React from "react";

const Terms = () => {
  return (
    <div className='w-full bg-white px-4 pt-10 pb-20 md:px-10'>
      <h1 className="text-2xl md:text-3xl">Terms and Conditions</h1>
      <p className="mt-5">
        Welcome to 10minutesPTE! These terms and conditions outline the rules
        and regulations for the use of our website. By accessing or using
        10minutesPTE, you accept and agree to comply with these terms and
        conditions. If you disagree with any part of these terms, please refrain
        from using our website.
      </p>
      <div className="flex flex-col gap-5 mt-5">
        <div>
          <h3 className="text-lg font-semibold mb-1">1. Intellectual Property</h3>
          <p>
            All content on 10minutesPTE, including text, graphics, logos,
            images, videos, and software, is the property of 10minutesPTE and is
            protected by intellectual property laws. You may not use, reproduce,
            modify, or distribute any of the content without prior written
            permission from 10minutesPTE.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-1">2. User Responsibilities</h3>
          <ul>
            <li className="my-1.5">
              a. You are solely responsible for the way you use 10minutesPTE.
              You agree not to engage in any activities that may disrupt or
              interfere with the proper functioning of the website.
            </li>
            <li className="my-1.5">
              b. You agree not to use 10minutesPTE for any unlawful or
              unauthorized purposes. You will comply with all applicable laws
              and regulations while using our website.
            </li>
            <li className="my-1.5">
              c. You are responsible for maintaining the confidentiality of your
              account information, including your username and password. You
              agree to notify us immediately of any unauthorized use of your
              account.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-1">3. Third-Party Links</h3>
          <p>
            10minutesPTE may contain links to third-party websites that are not
            owned or controlled by us. We have no control over the content,
            privacy policies, or practices of these websites. We do not endorse
            or assume any responsibility for the content or services provided by
            third parties.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-1">4. Disclaimer of Warranties</h3>
          <ul>
            <li className="my-1.5">
              a. 10minutesPTE makes no warranties or representations about the
              accuracy or completeness of the content on our website. We provide
              the materials and services on an "as is" and "as available" basis.
            </li>
            <li className="my-1.5">
              b. We do not guarantee that 10minutesPTE will be error-free,
              uninterrupted, or free of viruses or other harmful components. We
              recommend using up-to-date antivirus software to protect your
              devices.
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-1">5. Limitation of Liability</h3>
          <p>
            In no event shall 10minutesPTE be liable for any direct, indirect,
            incidental, consequential, or punitive damages arising out of your
            use or inability to use our website. This includes damages for lost
            profits, data, or any other intangible losses, even if we have been
            advised of the possibility of such damages.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-1">6. Modification and Termination</h3>
          <p>
            10minutesPTE reserves the right to modify, suspend, or terminate any
            part of the website at any time without prior notice. We may also
            revise these terms and conditions from time to time. By continuing
            to use our website after any changes, you agree to be bound by the
            updated terms and conditions.
          </p>
        </div>
        <p>
          By using 10minutesPTE, you acknowledge that you have read, understood,
          and agreed to these terms and conditions. If you have any questions or
          concerns, please contact us at [contact email].
        </p>
      </div>
    </div>
  );
};

export default Terms;
