import React from "react";

const Sell = () => {
  return (
    <div className="w-full bg-white px-4 py-10 md:px-10">
        <h1 className="text-center pb-10 heading"><span className="text_accent">Purchase</span> Study Materials</h1>
      <div className="items-center flex gap-8">
        <div className="my-4 flex w-full flex-col rounded-lg p-2 shadow-lg duration-300 hover:scale-105">
          {/* <img className="mx-auto mt-[-3rem] w-20 bg-white" src="" alt="/" /> */}
          <h2 className="py-8 text-center text-2xl font-bold">Templates Package</h2>
          <div className="text-center text-4xl font-bold">$7 <span className="line-through text-red-400 text-2xl">$10</span></div>
          <div className="text-center font-medium">
            <p className="mx-8 mt-8 border-b py-2">500 GB Storage</p>
            <p className="mx-8 border-b py-2">1 Granted User</p>
            <p className="mx-8 border-b py-2">Send up to 2 GB</p>
          </div>
          <button className="bg_accent mx-auto my-6 w-[200px]  rounded-md px-6 py-3 font-medium text-white">
            Buy
          </button>
          
        </div>
        <div className="my-4 flex w-full flex-col rounded-lg p-2 shadow-lg duration-300 hover:scale-105">
          {/* <img className="mx-auto mt-[-3rem] w-20 bg-white" src="" alt="/" /> */}
          <h2 className="py-8 text-center text-2xl font-bold">Vocabulary Package</h2>
          <div className="text-center text-4xl font-bold">$5</div>
          <div className="text-center font-medium">
            <p className="mx-8 mt-8 border-b py-2">500 GB Storage</p>
            <p className="mx-8 border-b py-2">1 Granted User</p>
            <p className="mx-8 border-b py-2">Send up to 2 GB</p>
          </div>
          <button className="bg_accent mx-auto my-6 w-[200px]  rounded-md px-6 py-3 font-medium text-white">
            Buy
          </button>
          
        </div>
        <div className="my-4 flex w-full flex-col rounded-lg p-2 shadow-lg duration-300 hover:scale-105">
          {/* <img className="mx-auto mt-[-3rem] w-20 bg-white" src="" alt="/" /> */}
          <h2 className="py-8 text-center text-2xl font-bold">Full Support</h2>
          <p className="text-center text-3xl font-bold">Contact Us</p>
          <div className="text-center font-medium">
            <p className="mx-8 mt-8 border-b py-2">500 GB Storage</p>
            <p className="mx-8 border-b py-2">1 Granted User</p>
            <p className="mx-8 border-b py-2">Send up to 2 GB</p>
          </div>
          <button className="bg_accent mx-auto my-6 w-[200px] rounded-md px-6 py-3 font-medium text-white">
            Buy
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sell;
