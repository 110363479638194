import React from "react";

const Promo = () => {
  const intToString = (num) => {
    num = num.toString().replace(/[^0-9.]/g, "");
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  };
 
  return (
    <div className="py-16 w-full bg-slate-50 px-4 md:px-10">
      <div className="mx-auto max-w-[1240px]">
        <div className="text-center">
          <h2 className="heading gre_orange">
            Trusted by students across the world.
          </h2>
          <p className="pt-2 pb-6 caption">
          Get your study materials, get ready, schedule your test, and then tell others about your success.
          </p>
        </div>

        <div className="grid gap-1 px-2 text-center md:grid-cols-3">
          <div className="rounded-xl bg-white border py-8 shadow-lg">
            <p className="text-2xl md:text-5xl font-semibold text_accent">100%</p>
            <p className="mt-2 text-gray-400">Success Rate</p>
          </div>
          <div className="rounded-xl bg-white border py-8 shadow-lg">
            <p className="text-2xl md:text-5xl font-semibold text_accent">24/7</p>
            <p className="mt-2 text-gray-400">Support</p>
          </div>
          <div className="rounded-xl bg-white border py-8 shadow-lg">
            <p className="text-2xl md:text-5xl font-semibold text_accent">
              {intToString(2550 + new Date().getDay())}
            </p>
            <p className="mt-2 text-gray-400">Transactions per day</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
