import { createSlice } from '@reduxjs/toolkit'
import { loginUser } from './allUserFunc'

const loginUserSlice = createSlice({
   name: 'loginUser',
   initialState: {
      userInfo: localStorage.getItem('10minUser')
         ? JSON.parse(localStorage.getItem('10minUser'))
         : {},
      status: null,
   },
   extraReducers: {
      [loginUser.pending]: (state) => {
         state.status = 'loading'
      },
      [loginUser.fulfilled]: (state, action) => {
         state.userInfo = action.payload
         state.status = 'success'
      },
      [loginUser.rejected]: (state, action) => {
         // state.errorMessage = action;
         state.status = 'failed'
      },
   },
})

export default loginUserSlice.reducer
