import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"

import createUserReducer from "./auth/createUserSlice"
import loginUserReducer from "./auth/loginUserSlice"
import registerUserReducer from "./auth/createUserSlice"
import { authApi } from "./auth/auth"

export const store = configureStore({
   reducer: {
      login: loginUserReducer,
      register: createUserReducer,
      registerUser: registerUserReducer,
      [authApi.reducerPath]: authApi.reducer,
   },
   middleware: (gDM) =>
      gDM().concat([
         authApi.middleware,
      ]),
})

setupListeners(store.dispatch)
