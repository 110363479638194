import React, { useRef, useState, useEffect } from "react";
import Typed from "typed.js";
import VerticalSlider from "./VerticalSlider";
import "../styles/wave.css";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const mainVideo = "./hero.mp4";

const Video = () => {
  const videoParentRef = useRef();
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];

      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return shouldUseImage ? (
    <img src={mainVideo} alt="Muted Video" />
  ) : (
    <div
      ref={videoParentRef}
      dangerouslySetInnerHTML={{
        __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${mainVideo}" type="video/mp4" />
        </video>`,
      }}
    />
  );
};

const Hero = () => {
  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["10minutesPTE.", "a low budget.", "time."],
      startDelay: 100,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 100,
      smartBackspace: true,
      loop: true,
      showCursor: true,
      // cursorChar: "!"
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <section className="wave wave-bottom z-10 px-4 py-10 md:px-10">
      <div className="container mx-auto flex items-center justify-center ">
        <div className="flex flex-col items-center justify-center gap-y-3 text-center md:gap-y-8 lg:flex-row lg:gap-x-[30px] lg:gap-y-0 lg:text-left">
          <div className="flex-1">
            <h1 className="heading mb-2">
              Prepare for your study abroad program on
            </h1>
            <span
              ref={el}
              className="mb-2 text-2xl font-semibold text-white md:text-5xl"
            ></span>
          </div>
          <div className="relative flex-1">
            <div className="p-4 saturate-[0.85] md:p-10">
              <Video />
            </div>
            <div className="absolute -bottom-1.5 right-0 hidden md:block">
              <VerticalSlider />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
