import React from "react";

const About = () => {
  return (
    <div class="px-4 py-24 md:px-10 flex items-center justify-center flex-col text-center">
      <h1 class="pb-8 text-2xl lg:text-3xl font-bold">
      Why 10minutesPTE?
      </h1>
      <p class="text-base leading-7">
        Welcome to 10minutesPTE! We are your trusted platform for PTE (Pearson
        Test of English) preparation, providing you with the tools and resources
        you need to excel in your language proficiency exam. Whether you're a
        student aiming to study abroad, a professional seeking to enhance your
        career prospects, or an individual looking to improve your language
        skills, we've got you covered.
      </p>
      <p class="text-base leading-7 mt-7">
        At 10minutesPTE, we understand that time
        is of the essence. That's why we have designed our platform to cater to
        your busy schedule. With our comprehensive study materials and
        innovative learning techniques, you can make the most of your limited
        time and achieve your desired PTE scores quickly and efficiently. Start your PTE
        preparation today and unlock a world of opportunities. Remember, at
        10minutesPTE, your success is just minutes away!
      </p>
    </div>
  );
};

export default About;
