import { useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

const Myaccount = () => {
  const { userInfo } = useSelector((state) => state.login);
  const [profile, setprofile] = useState("");

  const handlefile = (e) => {
    setprofile(e.target.files[0]);
  };

  const onSubmitprofile = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", profile);
    formData.append("userID", userInfo?.id);

    try {
      if (!profile)
        return enqueueSnackbar("Please upload an image first!", {
          variant: "error",
        });
      await axios
        .post("https://one0minutespte-backend.onrender.com/api/file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          localStorage.setItem("10minUser", JSON.stringify(res?.data));
          enqueueSnackbar(res?.data?.message, { variant: "success" });
          setTimeout(() => {
            window.location.reload();
          }, 300);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      if (err.response.status === 500) {
        console.log(err);
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  const updateValidation = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    //eslint-disable-line
         //eslint-disable-next-line
         /* eslint-disable no-useless-escape */
    validationSchema: Yup.object({
        //eslint-disable-line
         //eslint-disable-next-line
         /* eslint-disable no-useless-escape */
      email: Yup.string().matches(
        //eslint-disable-line
         //eslint-disable-next-line
         /* eslint-disable no-useless-escape */
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email format"
      ),//eslint-disable-line
      //eslint-disable-next-line
      /* eslint-disable no-useless-escape */
    }),
    onSubmit: async (values, { resetForm }) => {
      if (values?.password !== values?.confirmPassword) {
        enqueueSnackbar("Password must match", { variant: "error" });
      }else{
        await axios
          .patch(
            `https://one0minutespte-backend.onrender.com/api/auth/update-user`,
            Object.fromEntries(
              Object.entries({
                userId: userInfo?.id,
                name: values.name,
                email: values.email,
                password: values.password,
                confirmPassword: values.confirmPassword,
              }).filter(([_, v]) => v)
            ),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            localStorage.setItem("10minUser", JSON.stringify(res?.data));
            enqueueSnackbar(res?.data?.message, { variant: "success" });
            setTimeout(() => {
              window.location.reload();
            }, 300);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      resetForm();
    },
  });

  return (
    <div className="mb-20 mt-10">
       <SnackbarProvider />
      <h1 className="text-center text-xl font-semibold md:text-2xl">
        Account Settings
      </h1>
      <form onSubmit={updateValidation.handleSubmit}>
        <div className="container mx-auto my-5 flex w-full gap-5 bg-white p-5 md:w-2/3">
          <div className="hidden bg-gray-100 p-5 md:inline-block">
            <h2 className="mb-4 text-xl font-medium tracking-wide text-gray-700">
              Profile Info
            </h2>
            <p className="text-sm text-gray-500">
              Update your basic profile information such as Name, Email,
              Password, and Image.
            </p>
          </div>
          <div className="w-full px-4 md:px-0">
            <div className="flex flex-col gap-3">
              <input
                className="border-none bg-white p-2.5 text-sm shadow-sm"
                type="text"
                name="name"
                value={updateValidation.values.name}
                onBlur={updateValidation.handleBlur}
                onChange={updateValidation.handleChange}
                placeholder="Username"
              />

              <input
                className="border-none bg-white p-2.5 text-sm shadow-sm"
                type="email"
                name="email"
                value={updateValidation.values.email}
                onBlur={updateValidation.handleBlur}
                onChange={updateValidation.handleChange}
                placeholder="Email"
              />
              <input
                className="border-none bg-white p-2.5 text-sm shadow-sm"
                type="password"
                name="password"
                value={updateValidation.values.password}
                onBlur={updateValidation.handleBlur}
                onChange={updateValidation.handleChange}
                placeholder="Password"
              />
              <input
                className="border-none bg-white p-2.5 text-sm shadow-sm"
                type="password"
                name="confirmPassword"
                value={updateValidation.values.confirmPassword}
                onBlur={updateValidation.handleBlur}
                onChange={updateValidation.handleChange}
                placeholder="Confirm Password"
              />
              <div className="my-2 flex flex-col">
                <label className="px-2 text-sm text-gray-400">
                  Change Photo
                </label>
                <img
                  src={userInfo?.profile}
                  alt=""
                  className="my-2 ml-4 h-12 w-12 rounded-full bg-gray-200"
                />
                <div className="flex flex-col items-start justify-start gap-2 md:flex-row md:items-center">
                  <input
                    className="border-none bg-white p-2.5 text-sm shadow-sm"
                    type="file"
                    id="file"
                    name="file"
                    onChange={handlefile}
                  />
                  <button
                    onClick={onSubmitprofile}
                    className="text_accent border bg-white px-6 py-2 font-medium shadow-sm"
                  >
                    Upload
                  </button>
                </div>
              </div>
              <button className="bg_accent mt-4 px-6 py-2 text-white md:mt-0">
                Update
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Myaccount;
