import {createAsyncThunk} from "@reduxjs/toolkit"
import axios from "axios"

export const createUser = createAsyncThunk(
  "createUser/createUser",
  async (user) => {
    const {data} = await axios.post(`https://one0minutespte-backend.onrender.com/api/auth/register`, user)
    return data
  }
)

export const loginUser = createAsyncThunk(
  "loginUser/loginUser",
  async (user) => {
    const {data} = await axios.post(`https://one0minutespte-backend.onrender.com/api/auth/login`, user)
    localStorage.setItem("10minUser", JSON.stringify(data))
    if (data) window.location.href = "/"
    return data
  }
)

export const logoutUser = createAsyncThunk(
  "logoutUser/logoutUser",
  async () => {
    localStorage.removeItem("10minUser")
    window.location.href = "/"
  }
)
