import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
   reducerPath: 'authApi',
   baseQuery: fetchBaseQuery({ baseUrl: '/' }),
   tageTypes: ['Auth'],
   endpoints: (builder) => ({
      getUsers: builder.query({
         query: () => `api/auth/all-users`,
         providesTags: ['Auth'],
      }),
      getUser: builder.query({
         query: (id) => `api/auth/user/${id}`,
         providesTags: ['Auth'],
      }),
      editUser: builder.mutation({
         query: ({ id, ...rest }) => ({
            url: `api/auth/${id}`,
            method: 'PUT',
            body: rest,
         }),
         invalidatesTags: ['Auth'],
      }),
      deleteUser: builder.mutation({
         query: (id) => ({
            url: `api/users/${id}`,
            method: 'DELETE',
         }),
         invalidatesTags: ['Auth'],
      }),
   }),
})

export const {
   useGetUsersQuery,
   useGetUserQuery,
   useEditUserMutation,
   useDeleteUserMutation,
} = authApi
