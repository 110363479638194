import React from "react"
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./services/store"
import App from './App'
import './styles/index.css'

const rootElement = document.getElementById( "root" )
const root = createRoot( rootElement )
root.render(
    <BrowserRouter>
        <Provider store={ store }>
            <App />
        </Provider>
    </BrowserRouter>
)
