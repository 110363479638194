import { useState } from "react";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import axios from "axios";
// import { AiOutlinePhone } from "react-icons/ai";
// import { CiMail } from "react-icons/ci";

const Contact = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const { userInfo } = useSelector((state) => state.login);

  const handleContact = async (e) => {
    e.preventDefault();
    if (userInfo?.email) {
      await axios
        .post(
          "https://one0minutespte-backend.onrender.com/api/contact",
          { email, name, message },
          {
            headers: {
              Authorization: `${userInfo?.refreshToken}`,
            },
          }
        )
        .then((response) => {
          enqueueSnackbar("We will contact you asap", { variant: "success" });
        })
        .catch((error) => {
          // console.log(error?.response?.data?.message)
          enqueueSnackbar(error?.response?.data?.message, {
            variant: "error",
          });
        });
      setname("");
      setemail("");
      setmessage("");
    } else {
      window.location.href = "/auth";
    }
  };

  return (
    <section className="flex items-center justify-center gap-5 px-4 py-24 md:px-10">
      <SnackbarProvider />
      <div className="flex flex-col rounded-md bg-white p-5 shadow-xl">
        <h3 className="text-2xl md:text-3xl">Contact Us</h3>
        <p className="mt-2 w-[80%] text-gray-400">
          Feel Free to contact us any time. We will get back to you as soon as
          we can.
        </p>
        <input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setname(e.target.value)}
          className="my-2 mt-5 rounded-md border-none p-2 shadow outline-none"
          placeholder="Name"
        />
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
          className="my-2 rounded-md border-none p-2 shadow outline-none"
          placeholder="Email"
          required
        />
        <textarea
          className="my-2 rounded-md border-none p-2 shadow outline-none"
          placeholder="Message"
          name="message"
          value={message}
          onChange={(e) => setmessage(e.target.value)}
        ></textarea>
        <button
          onClick={handleContact}
          className="bg_accent mt-5 px-6 py-2 text-white"
        >
          Send
        </button>
      </div>
    </section>
  );
};

export default Contact;
