import React from "react";
import Slider from "react-slick";
import FeedbackCard from "./FeedbackCard";
import "../styles/slider.css";

const Feedback = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 6000,
    cssEase: "linear",
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  const data = [
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material. After purchasing it and diligent preparation, I took my test quickly and received my desired score of 8.",
    },
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material. After purchasing it and diligent preparation, I took my test quickly and received my desired score of 8.",
    },
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material. After purchasing it and diligent preparation, I took my test quickly and received my desired score of 8.",
    },
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material. After purchasing it and diligent preparation, I took my test quickly and received my desired score of 8.",
    },
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material. After purchasing it and diligent preparation, I took my test quickly and received my desired score of 8.",
    },
  ];
  return (
    <div className="w-full bg-white py-10">
      <div className="m-auto max-w-[600px] px-4 md:max-w-[1480px] md:px-10">
        <div className="py-4">
          <h1 className="sub_heading py-3">
            Students' <span className="text_accent">Feedback</span>
          </h1>
          <p className="caption">Use it to improve your learning.</p>
        </div>
        <Slider {...settings}>
          {data?.map((x, i) => (
            <FeedbackCard x={x} key={i} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Feedback;
