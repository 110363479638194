import React from "react";

const Privacy = () => {
  return (
    <div className="w-full bg-white px-4 pb-20 pt-10 md:px-10">
      <h1 className="text-2xl md:text-3xl">Privacy Policy</h1>
      <p className="mt-5">
        At 10minutesPTE, we value and respect your privacy. This Privacy Policy
        outlines how we collect, use, and protect your personal information when
        you visit our website or use our services. We are committed to
        maintaining the confidentiality and security of your personal data. By
        accessing our website or using our services, you consent to the
        practices described in this policy.
      </p>
      <div className="mt-5 flex flex-col gap-5">
        <div>
          <h3 className="mb-1 text-lg font-semibold">
            1. Information we collect:
          </h3>
          <p>
            When you visit our website, we may collect certain information
            automatically, such as your browser type, device information, and
            usage data. We may also collect personal information that you
            voluntarily provide to us, such as your name, email address, and
            other contact details when you register for an account or subscribe
            to our services.
          </p>
        </div>
        <div>
          <h3 className="mb-1 text-lg font-semibold">2. Use of information:</h3>
          <p>
            We use the collected information to improve and personalize your
            experience on our website, to provide the services you have
            requested, and to communicate with you about our products, updates,
            and promotional offers. We may also use the information to analyze
            user behavior, monitor website traffic, and enhance our services.
          </p>
        </div>
        <div>
          <h3 className="mb-1 text-lg font-semibold">
            3. Cookies and similar technologies:
          </h3>
          <p>
            We may use cookies, web beacons, and similar technologies to collect
            information about your browsing activities on our website. These
            technologies help us optimize your user experience, track usage
            patterns, and gather statistical data. You can modify your browser
            settings to decline cookies, but this may affect your ability to
            access certain features of our website.
          </p>
        </div>
        <div>
          <h3 className="mb-1 text-lg font-semibold">4. Data security:</h3>
          <p>
            We employ industry-standard security measures to protect your
            personal information from unauthorized access, disclosure,
            alteration, or destruction. However, please note that no method of
            transmission over the internet or electronic storage is 100% secure,
            and we cannot guarantee absolute security.
          </p>
        </div>

        <div>
          <h3 className="mb-1 text-lg font-semibold">
            5. Third-party disclosure:
          </h3>
          <p>
            We do not sell, trade, or transfer your personal information to
            third parties without your consent, except for trusted service
            providers who assist us in operating our website and delivering our
            services. These third-party providers are bound by strict
            confidentiality agreements and are authorized to use your
            information solely for the purpose of providing the requested
            services.
          </p>
        </div>
        <div>
          <h3 className="mb-1 text-lg font-semibold">6. External links:</h3>
          <p>
            Our website may contain links to third-party websites. Please note
            that we are not responsible for the privacy practices or content of
            those websites. We encourage you to review the privacy policies of
            any third-party sites you visit.
          </p>
        </div>
        <div>
          <h3 className="mb-1 text-lg font-semibold">
            7. Changes to the Privacy Policy:
          </h3>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time. Any changes will be effective immediately upon posting the
            revised policy on our website. We encourage you to review this
            policy periodically to stay informed about how we collect, use, and
            protect your personal information.
          </p>
        </div>
        <p>
          If you have any questions or concerns regarding our Privacy Policy,
          please contact us at [contact email].
        </p>
      </div>
    </div>
  );
};

export default Privacy;
