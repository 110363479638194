import React from "react";

const FeedbackCard = ({ x }) => {
  return (
    <div className="mx-2 my-8 rounded-2xl bg-white p-5 shadow-lg">
      <div className="flex justify-between">
        <div className="flex gap-4">
          <img src={x?.photo} className="w-14 rounded-full" alt="" />
          <div>
            <h1>{x?.author}</h1>
            <p>{x?.profession}</p>
          </div>
        </div>
        <img className="h-8" src="./10.png" alt="" />
      </div>
      <div className="py-8">
        <h3 className="text-lg">{x?.comments}</h3>
      </div>
    </div>
  );
};

export default FeedbackCard;
