import React from "react";
import { Viewer, Worker, ProgressBar } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "../styles/free.css";

const renderPage = (props) => {
  return (
    <>
      {props.canvasLayer.children}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          height: "100%",
          justifyContent: "center",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            color: "rgba(0, 0, 0, 0.1)",
            fontSize: `${4 * props.scale}rem`,
            fontWeight: "bold",
            textTransform: "uppercase",
            transform: "rotate(-45deg)",
            userSelect: "none",
          }}
        >
          10minutesPTE
        </div>
      </div>
      {props.annotationLayer.children}
      {props.textLayer.children}
    </>
  );
};

const Free = () => {
  return (
    <div className="flex flex-col gap-5 px-4 py-24 md:px-10">
      <p>
        If you're a candidate hoping to achieve the greatest score on the
        Pearson Test of English but aren't seeing the desired results owing to a
        lack of the finest PTE study materials, Language Academy is your best
        option for PTE course preparation.
      </p>
      <p className="mt-5 text-2xl font-bold md:text-3xl">
        Why 10minutesPTE for PTE exam preparation?
      </p>
      <ul class="burst flex flex-col gap-3 text-xl">
        <li className="freeli text-xl">
          Utilization of our practice materials and resources.
        </li>
        <li className="freeli text-xl">
          In our templates bundle, one may also find instructions for pte
          templates.
        </li>
        <li className="freeli text-xl">
          Our vocabulary bundle also includes words for pte, which one may
          learn.
        </li>
        <li className="freeli text-xl">
          To ace your test, learn efficient approaches and strategies.
        </li>
      </ul>
      <p className="mt-5 text-2xl font-bold md:text-3xl">
        Answer Short Questions
      </p>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          theme={{
            theme: "light",
          }}
          fileUrl="./asq.pdf"
          renderPage={renderPage}
          renderLoader={(percentages) => (
            <div style={{ width: "100%" }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
        />
      </Worker>
      <p className="mt-5 text-2xl font-bold md:text-3xl">
        Vocabulary
      </p>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          theme={{
            theme: "light",
          }}
          fileUrl="./vocabulary.pdf"
          renderPage={renderPage}
          renderLoader={(percentages) => (
            <div style={{ width: "100%" }}>
              <ProgressBar progress={Math.round(percentages)} />
            </div>
          )}
        />
      </Worker>
    </div>
  );
};

export default Free;
