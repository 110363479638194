import React from "react";
import { cta } from "../assets";
import {Link} from 'react-router-dom'

const CTA = () => {
  return (
    <div className="w-full bg-orange-50 py-10">
      <div className="m-auto grid max-w-[600px] items-center gap-8 px-4 md:max-w-[1480px] md:grid-cols-2 md:px-10">
        <img src={cta} className="mx-auto w-[650px]" alt=''/>
        <div>
          <h1 className="sub_heading py-2">
            <span className="text_accent">Join us</span> for the quickest
            success.
          </h1>
          <p className="caption py-2">
            Sign up for free to get started.
          </p>
          <div className="my-8">
         <Link
          className="bg_accent my-4 rounded-md px-8 py-4 font-semibold text-white max-[780px]:w-full"
            to="/auth"
          >
            Sign Up For Free
          </Link>
         </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
