import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { hamburgerMenu, close } from "../assets";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser } from "../services/auth/allUserFunc";
// import { useGetUserQuery } from "../services/auth/auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.login);
  let NAME = userInfo?.name || "No User Found";
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = [...NAME.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  const [toggle, setToggle] = useState(false);
  const handleClick = () => setToggle(!toggle);

  const handlelogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  return (
    <div className="h-[80px] w-full border-b bg-white px-0 md:px-10">
      <div className="m-auto flex h-full w-full max-w-[600px] items-center justify-between px-4 md:max-w-[1480px] md:px-0">
        <Link to="/" className="flex items-center">
          <img src="./10.png" alt="" className="w-14 p-2" />{" "}
          <span className="text-[22px] font-medium">minutesPTE</span>
        </Link>
        <div className="hidden items-center md:flex ">
          <ul className="flex gap-4">
            <Link to="/myPTE">
              {" "}
              <li className="cursor-pointer px-6 py-2 hover:bg-gray-100">
                myPTE
              </li>
            </Link>
            <Link to="/free">
              {" "}
              <li className="cursor-pointer px-6 py-2 hover:bg-gray-100">
                Free Materials
              </li>
            </Link>
            <Link to="/about-us">
              {" "}
              <li className="cursor-pointer px-6 py-2 hover:bg-gray-100">
                About Us
              </li>
            </Link>
            <Link to="/contact-us">
              {" "}
              <li className="cursor-pointer px-6 py-2 hover:bg-gray-100">
                Contact Us
              </li>
            </Link>
          </ul>
        </div>
        {userInfo?.email ? (
          <Menu as="div" className="relative z-50 hidden text-left md:block">
            <div>
              <Menu.Button className="flex w-full items-center justify-start gap-1 rounded-md border bg-white p-2">
                {userInfo?.profile ? (
                  <div className="flex h-10 w-10 items-center justify-center">
                    <img
                      src={userInfo?.profile}
                      alt=""
                      className="h-full w-full rounded-full"
                    />
                  </div>
                ) : (
                  <div class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-100">
                    <span class="font-medium text-gray-600">{initials}</span>
                  </div>
                )}
                <p className="text-gray-600">
                  {userInfo?.name.substring(0, 10)}
                </p>
                <IoMdArrowDropdown className="text-gray-400" size={35} />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/auth/myaccount"
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        Account Settings
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handlelogout}
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block w-full px-4 py-2 text-left text-sm"
                        )}
                      >
                        Sign out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <Link
            to="/auth"
            className="bg_accent hidden rounded-md px-8 py-4 font-semibold text-white md:block"
          >
            Sign Up For Free
          </Link>
        )}

        <div className="md:hidden" onClick={handleClick}>
          <img
            src={toggle ? close : hamburgerMenu}
            alt=""
            className="cursor-pointer"
          />
        </div>
      </div>

      <div
        className={
          toggle
            ? "absolute z-50 w-full border-b bg-white p-4 px-8 md:hidden"
            : "hidden"
        }
      >
        <ul>
          <Link to="/myPTE">
            {" "}
            <li className="cursor-pointer p-4 hover:bg-gray-100">myPTE</li>
          </Link>
          <Link to="/free">
            {" "}
            <li className="cursor-pointer p-4 hover:bg-gray-100">
              Free Materials
            </li>
          </Link>
          <Link to="/about-us">
            {" "}
            <li className="cursor-pointer p-4 hover:bg-gray-100">About Us</li>
          </Link>
          <Link to="/contact-us">
            {" "}
            <li className="cursor-pointer p-4 hover:bg-gray-100">Contact Us</li>
          </Link>
         <div className="mt-10 mb-5">
         <Link
            className="bg_accent w-full rounded-md px-8 py-4 font-semibold text-white"
            to="/auth"
          >
            Sign Up For Free
          </Link>
         </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
