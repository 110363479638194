import { useGetUserQuery } from "../services/auth/auth";
import { useSelector } from "react-redux";

const MyPTE = () => {
  const { userInfo } = useSelector((state) => state.login);
  const { data } = useGetUserQuery(userInfo.id);
  return (
    <div className="flex flex-col items-center justify-center gap-10 px-4 py-24 md:flex-row md:px-10">
      {data?.templatePurchase === false &&
        data?.vocabularyPurchase === false && (
          <div className="flex flex-col items-center rounded-xl justify-center bg-white p-10 md:p-20 text-center">
            <p className="mb-5 text-2xl font-bold md:text-3xl">
            Do you need to get ready quickly?
            </p>
            <p className="text-base md:text-lg">
              Your purchase materials will be obtained from here. Purchase the
              supplies or bundle of your choice now, and get started. By using
              our materials and tools, you will be fully prepared for your
              forthcoming PTE exam in a short amount of time.
            </p>
          </div>
        )}
      {data?.templatePurchase===true && (
        <div className="w-full rounded-xl bg-white p-10 shadow-xl md:w-[500px]">
          <p className="text-center text-xl font-semibold uppercase text-orange-500">
            PTE Templates
          </p>
          <hr className="my-4" />
          <div className="flex flex-col gap-10">
            <ul className="flex items-center justify-between">
              <li>Describe Image Template</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>Retell Lecture Template</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>Answer Short Questions</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>SWT Template</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>Essay Template</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>SST Template</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
          </div>
        </div>
      )}
      {data?.vocabularyPurchase===true && (
        <div className="w-full rounded-xl bg-white px-5 py-10 shadow-xl md:w-[500px]">
          <p className="text-center text-xl font-semibold uppercase text-orange-500">
            PTE Vocabulary
          </p>
          <hr className="my-4" />
          <div className="flex flex-col gap-10">
            <ul className="flex items-center justify-between">
              <li>All Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>English to Chinese Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>English to Hindi Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>English to Urdu Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>English to Nepali Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
            <ul className="flex items-center justify-between">
              <li>English to Bangla Vocabulary</li>
              <li className="bg_accent cursor-pointer rounded-md px-2 py-1 text-sm text-white">
                Download
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPTE;
