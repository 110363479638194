import React from "react";
import Slider from "react-slick";

const VerticalSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    // fade: true,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
      console.log("before change", currentSlide, nextSlide);
    },
    afterChange: function (currentSlide) {
      console.log("after change", currentSlide);
    },
  };
  const data = [
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material.",
    },
    {
      author: "Wahid Tarafder",
      photo:
        "https://media.licdn.com/dms/image/C5603AQF66zqkL2LakA/profile-displayphoto-shrink_800_800/0/1644635478158?e=2147483647&v=beta&t=3bGaT1YU7fWzLG9wgoJm87Eezc6kmwOWUOyNf3W41AE",
      profession: "Student",
      comments:
        "First of all, I want to appreciate 10minutesPTE for their material.",
    },
  ];
  return (
    <div className="w-[320px] mr-4">
      <Slider {...settings}>
        {data?.map((x, i) => (
          <p className="text-sm bg-transparant p-2.5 rounded-3xl" key={i}>
           <span className="font-semibold text-orange-500">David w.</span> purchase all template package.
          </p>
        ))}
      </Slider>
    </div>
  );
};

export default VerticalSlider;
