import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../services/auth/allUserFunc";
import { loginUser } from "../services/auth/allUserFunc";
import { useFormik } from "formik";
import * as Yup from "yup";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import "../styles/auth.css";

const Auth = () => {
  const { status: signupStatus } = useSelector((state) => state.register);
  // const { status: loginStatus } = useSelector((state) => state.login);

  const dispatch = useDispatch();

  const signup_validation = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name field required"),
      email: Yup.string()
        .required("Email field required")
         //eslint-disable-line
         //eslint-disable-next-line
        .matches(
          //eslint-disable-line
         //eslint-disable-next-line
         /* eslint-disable no-useless-escape */
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email format"
        ),//eslint-disable-line
        //eslint-disable-next-line
        /* eslint-disable no-useless-escape */
      password: Yup.string().required("Password field required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(createUser(values));
      resetForm();
    },
  });

  const login_validation = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email field required")
          //eslint-disable-line
         //eslint-disable-next-line
        .matches(
          //eslint-disable-line
         //eslint-disable-next-line
         /* eslint-disable no-useless-escape */
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email format"
        ),//eslint-disable-line
        //eslint-disable-next-line
        /* eslint-disable no-useless-escape */
      password: Yup.string().required("Password field required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(loginUser(values));
      resetForm();
    },
  });

  useEffect(() => {
    if (signupStatus === "success")
      enqueueSnackbar("Now you can login", { variant: "success" });
  }, [signupStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="waveContainer relatvie flex items-center justify-center">
      <SnackbarProvider />
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" class="viewBox">
        <path
          d="M0.00,49.98 C254.51,72.06 306.43,22.41 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          class="path"
        ></path>
      </svg>
      <div class="absolute h-[450px] w-[300px] overflow-hidden rounded-md bg-white shadow-md">
        <input
          className="input bg-white p-2 text-sm shadow-sm"
          type="checkbox"
          id="chk"
          aria-hidden="true"
        />

        <div class="signup">
          <form onSubmit={signup_validation.handleSubmit}>
            <label
              className="label text-orange-400"
              for="chk"
              aria-hidden="true"
            >
              Sign up
            </label>
            <input
              className={
                signup_validation.touched.name && signup_validation.errors.name
                  ? "input border-2 border-red-500 bg-white p-2 text-sm shadow-sm placeholder:text-red-500"
                  : "input border-none bg-white p-2 text-sm shadow-sm"
              }
              type="text"
              name="name"
              value={signup_validation.values.name}
              onBlur={signup_validation.handleBlur}
              onChange={signup_validation.handleChange}
              placeholder={
                signup_validation.touched.name && signup_validation.errors.name
                  ? "Username required!"
                  : "Username"
              }
            />

            <input
              className={
                signup_validation.touched.email && signup_validation.errors.email
                  ? "input border-2 border-red-500 bg-white p-2 text-sm shadow-sm placeholder:text-red-500"
                  : "input border-none bg-white p-2 text-sm shadow-sm"
              }
              type="email"
              name="email"
              value={signup_validation.values.email}
              onBlur={signup_validation.handleBlur}
              onChange={signup_validation.handleChange}
              placeholder={
                signup_validation.touched.email && signup_validation.errors.email
                  ? "Email required!"
                  : "Email"
              }
            />

            <input
              className={
                signup_validation.touched.email && signup_validation.errors.email
                  ? "input border-2 border-red-500 bg-white p-2 text-sm shadow-sm placeholder:text-red-500"
                  : "input border-none bg-white p-2 text-sm shadow-sm"
              }
              type="password"
              name="password"
              value={signup_validation.values.password}
              onBlur={signup_validation.handleBlur}
              onChange={signup_validation.handleChange}
              placeholder={
                signup_validation.touched.password && signup_validation.errors.password
                  ? "Password required!"
                  : "Password"
              }
            />

            <button className="button bg_accent">Sign up</button>
          </form>
        </div>

        <div class="login">
          <form onSubmit={login_validation.handleSubmit}>
            <label
              className="label text-orange-400"
              for="chk"
              aria-hidden="true"
            >
              Login
            </label>
            <input
              className={
                login_validation.touched.email && login_validation.errors.email
                  ? "input border-2 border-red-500 bg-white p-2 text-sm shadow-sm placeholder:text-red-500"
                  : "input border-none bg-white p-2 text-sm shadow-sm"
              }
              type="email"
              name="email"
              value={login_validation.values.email}
              onBlur={login_validation.handleBlur}
              onChange={login_validation.handleChange}
              placeholder={
                login_validation.touched.email && login_validation.errors.email
                  ? "Email required!"
                  : "Email"
              }
            />
            <input
              className={
                login_validation.touched.password && login_validation.errors.password
                  ? "input border-2 border-red-500 bg-white p-2 text-sm shadow-sm placeholder:text-red-500"
                  : "input border-none bg-white p-2 text-sm shadow-sm"
              }
              type="password"
              name="password"
              value={login_validation.values.password}
              onBlur={login_validation.handleBlur}
              onChange={login_validation.handleChange}
              placeholder={
                login_validation.touched.password && login_validation.errors.password
                  ? "Password required!"
                  : "Password"
              }
            />
            <button className="button bg_accent">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Auth;
